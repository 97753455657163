import {
  adaDecimals,
  allAssets,
  ethChainId,
  maticDecimals,
  polygonChainId,
} from '../config'
import { getUtxos } from './cardano/transaction'
import { fromQuantity, toQuantity } from './util'
import { getAddress } from '@ethersproject/address'
import { BigNumber } from 'bignumber.js'
import {
  AssetType,
  CardanoAssetConfig,
  ChainType,
  WalletId,
} from 'crypto/interface'
import * as akamon from 'crypto/lib/akamon'
import { submitTransaction as submitCardanoTransaction } from 'crypto/lib/cardano/connector'
import * as SL from 'crypto/lib/cardano/serializationLib'
import * as cardano from 'crypto/lib/cardano/transaction'
import * as eternl from 'crypto/lib/cardano/wallet/eternl'
import * as nami from 'crypto/lib/cardano/wallet/nami'
import * as metamask from 'crypto/lib/ethereum/metamask'

export type Transaction = {
  fee: BigNumber
  protocolFee?: BigNumber
  amount: BigNumber
  submit: (password: string) => Promise<string>
}

async function createMetamaskTransaction(
  chain: 'ethereum' | 'polygon',
  decimals: number,
  contract: string | undefined,
  address: string,
  amount: string
): Promise<Transaction> {
  let chainId: string
  switch (chain) {
    case 'ethereum':
      chainId = ethChainId
      break
    case 'polygon':
      chainId = polygonChainId
      break
  }

  const quantity = toQuantity(amount, decimals)
  let metamaskTransaction
  if (contract) {
    metamaskTransaction = await metamask.createERC20Transaction(
      chainId,
      contract,
      address,
      quantity
    )
  } else {
    metamaskTransaction = await metamask.createEthTransaction(
      chainId,
      address,
      quantity
    )
  }
  return { ...metamaskTransaction, amount: new BigNumber(amount) }
}

/**
 * Concatenates two Vkeywitnesses
 *
 * IMPORTANT NOTE: fst and snd might be mutated after the function call
 */
function concatVkeywitnesses(
  fst: SL.Vkeywitnesses | undefined,
  snd: SL.Vkeywitnesses | undefined
): SL.Vkeywitnesses | undefined {
  if (fst && snd) {
    for (let i = 0; i < snd.len(); i++) {
      fst.add(snd.get(i))
    }
    return fst
  } else {
    return fst || snd
  }
}

function createCardanoTxSubmit(
  transaction: SL.Transaction,
  walletId: 'nami' | 'eternl',
  address: string
): (password: string) => Promise<string> {
  return async (_password: string) => {
    const witnessSetFromWallet = await (walletId === 'nami'
      ? nami
      : eternl
    ).signTransaction(transaction)

    // Note: we PREPEND the vkeys from wallet to follow the behaviour of cardano-cli
    const transactionWitnessSet = transaction.witness_set()
    const combinedVkeys = concatVkeywitnesses(
      witnessSetFromWallet.vkeys(),
      transactionWitnessSet.vkeys()
    )
    combinedVkeys && transactionWitnessSet.set_vkeys(combinedVkeys)

    const signedTransaction = SL.Transaction.new(
      transaction.body(),
      transactionWitnessSet,
      transaction.auxiliary_data()
    )
    return await submitCardanoTransaction(address, signedTransaction)
  }
}

async function createCardanoTransaction(
  walletId: 'nami' | 'eternl',
  decimals: number,
  config: CardanoAssetConfig,
  address: string,
  amount: string
): Promise<Transaction> {
  const from = (walletId === 'nami' ? nami : eternl).getAddress()

  const quantity = toQuantity(amount, decimals)
  let lovelace
  let assets: cardano.Asset[] = []
  if (config.isCoin) {
    lovelace = quantity
  } else {
    assets = [
      { assetName: config.assetName, policyId: config.policyId, quantity },
    ]
  }
  const {
    transaction,
    lovelace: lovelaceResult,
    fee: feeResult,
  } = await cardano.createTransaction(walletId, from, address, lovelace, assets)

  let fee = fromQuantity(feeResult, adaDecimals)
  const ada = fromQuantity(lovelaceResult, adaDecimals)
  if (!config.isCoin) {
    fee = fee.plus(ada)
  }

  return {
    fee,
    amount: config.isCoin ? ada : new BigNumber(amount),
    submit: createCardanoTxSubmit(transaction, walletId, address),
  }
}

export function createTransaction(
  walletId: WalletId,
  chain: ChainType,
  assetId: AssetType,
  address: string,
  amount: string
): Promise<Transaction> {
  const unexpectedChain = Error(
    `Unexpected params: wallet: ${walletId}, chain: ${chain}, asset: ${assetId}`
  )
  const config = allAssets.find((asset) => asset.id === assetId)!

  if (walletId === 'metamask') {
    if (chain === 'cardano') throw unexpectedChain
    const chainConfig = config[chain]
    if (!chainConfig) throw unexpectedChain

    return createMetamaskTransaction(
      chain,
      chainConfig.decimals,
      chainConfig.address,
      address,
      amount
    )
  } else {
    if (chain !== 'cardano' || !config.cardano) throw unexpectedChain
    if (walletId !== 'nami' && walletId !== 'eternl') throw unexpectedChain
    const decimals = config.cardano.decimals
    return createCardanoTransaction(
      walletId,
      decimals,
      config.cardano,
      address,
      amount
    )
  }
}

export async function createAkamonBetaTransaction(
  walletId: WalletId,
  assetId: AssetType,
  fromChain: ChainType,
  toChain: ChainType,
  toAddress: string,
  amount: string
): Promise<Transaction> {
  const unexpectedParams = Error(
    `Unexpected bridge params: walletId: ${walletId}, assetId: ${assetId}, fromChain: ${fromChain}, toChain: ${toChain}`
  )

  function assert(condition: boolean): asserts condition {
    if (!condition) throw unexpectedParams
  }

  assert(fromChain !== toChain)
  assert(fromChain !== 'ethereum' && toChain !== 'ethereum')
  assert(assetId !== 'eth')

  const config = allAssets.find((asset) => asset.id === assetId)
  assert(!!config)
  const chainConfig = config[fromChain]
  assert(!!chainConfig)
  const chainDecimals = chainConfig.decimals
  const coinDecimals = fromChain === 'cardano' ? adaDecimals : maticDecimals

  const nativeToken = config.priceTicker
  const quotationType = config.nativeChain === toChain ? 'Unwrap' : 'Wrap'
  const requestAmount = toQuantity(amount, chainDecimals)

  // --- metamask
  if (walletId === 'metamask') {
    assert(fromChain === 'polygon' && toChain === 'cardano')

    const { quotation, bridgeAddress, signature, signer } =
      await akamon.requestPolygonQuotation({
        destinationChain: toChain,
        recipientAddress: toAddress,
        type: quotationType, // Wrap/Unwrap
        token: nativeToken,
        requestAmount: requestAmount.toString(),
      })

    const createTx =
      quotationType === 'Wrap' ? metamask.wrapToken : metamask.unwrapToken
    const tx = await createTx({
      token: nativeToken,
      bridgeAddress,
      requestAmount,
      volumeFee: quotation.protocolFee.volumeFee,
      minAmount: quotation.protocolFee.minAdaFee.minLovelaceAmount,
      minFee: quotation.protocolFee.minAdaFee.feeValue,
      expirationBlock: quotation.expirationBlock,
      recipientAddress: toAddress,
      quoterAddress: signer,
      signatureHex: signature,
    })

    const submit = (_password: string) => tx.submit()
    return {
      fee: tx.fee,
      protocolFee: fromQuantity(
        quotation.protocolFee.volumeFee.plus(
          quotation.protocolFee.minAdaFee.feeValue
        ),
        coinDecimals
      ),
      amount: new BigNumber(amount),
      submit,
    }
  } else if (walletId === 'nami' || walletId === 'eternl') {
    assert(fromChain === 'cardano' && toChain === 'polygon')
    assert(!!config.cardano)

    const utxos = await getUtxos(walletId)
    const fromAddress = (walletId === 'nami' ? nami : eternl).getAddress()
    const { tx, quotation } = await akamon.requestCardanoQuotation({
      requestInfo: {
        destinationChain: toChain,
        recipientAddress: getAddress(toAddress),
        type: quotationType,
        token: nativeToken,
        requestAmount: requestAmount.toString(),
      },
      utxoInfo: {
        changeAddress: fromAddress,
        inputs: cardano.formatTransactionInputs(utxos),
      },
    })
    return {
      fee: fromQuantity(tx.body().fee().to_str(), coinDecimals),
      protocolFee: fromQuantity(quotation.protocolFee.volumeFee, coinDecimals),
      amount: new BigNumber(amount),
      submit: createCardanoTxSubmit(tx, walletId, fromAddress),
    }
  } else {
    assert(false)
  }
}
