import * as connector from './connector'
import BigNumber from 'bignumber.js'
import { isVasil } from 'crypto/config'
import * as SL from 'crypto/lib/cardano/serializationLib'
import * as eternl from 'crypto/lib/cardano/wallet/eternl'
import * as meld from 'crypto/lib/cardano/wallet/meld'
import * as nami from 'crypto/lib/cardano/wallet/nami'

export type Asset = {
  policyId: string
  assetName: string
  quantity: BigNumber
}

export type TransactionResult = {
  transaction: SL.Transaction
  lovelace: BigNumber
  fee: BigNumber
}

export async function getUtxos(
  walletId: number | 'nami' | 'eternl'
): Promise<SL.TransactionUnspentOutput[]> {
  let getter
  if (typeof walletId === 'number') {
    getter = () => meld.getUtxos(walletId)
  } else if (walletId === 'nami') {
    getter = nami.getUtxos
  } else {
    getter = eternl.getUtxos
  }
  return toUtxos(await getter())
}

export function toUtxos(utxoStrings: string[]): SL.TransactionUnspentOutput[] {
  return utxoStrings.map((utxoString) =>
    SL.TransactionUnspentOutput.from_bytes(Buffer.from(utxoString, 'hex'))
  )
}

function getMinAda(
  protocolParameter: connector.ProtocolParams,
  output: SL.TransactionOutput
): SL.BigNum {
  if (isVasil) {
    const dataCost = SL.DataCost.new_coins_per_byte(
      SL.BigNum.from_str(protocolParameter.coinsPerUtxoSize)
    )
    return SL.min_ada_for_output(output, dataCost)
  } else {
    return SL.min_ada_required(
      output.amount(),
      true,
      SL.BigNum.from_str(protocolParameter.coinsPerUtxoWord)
    )
  }
}

export async function maxLovelace(
  walletId: 'nami' | 'eternl',
  address: string
): Promise<string | undefined> {
  try {
    const protocolParameter = await connector.fetchProtocolParams()
    const utxos = await getUtxos(walletId)

    const value = utxos.reduce((value, utxo) => {
      return value.checked_add(utxo.output().amount())
    }, SL.Value.zero())
    let maxAda = value.coin()

    const multiasset = value.multiasset()
    if (multiasset && multiasset?.len()) {
      const outputBuilder = SL.TransactionOutputBuilder.new()
        .with_address(SL.Address.from_bech32(address))
        .next()
      const output = outputBuilder
        .with_coin_and_asset(SL.BigNum.zero(), multiasset)
        .build()

      const minAda = getMinAda(protocolParameter, output)
      maxAda = maxAda.checked_sub(minAda)
    }

    const maxFee = new BigNumber(protocolParameter.linearFee.minFeeA)
      .times(protocolParameter.maxTxSize)
      .plus(protocolParameter.linearFee.minFeeB)

    const maxLovelace = new BigNumber(maxAda.to_str())

    const { fee } = await createTransaction(
      walletId,
      address,
      address,
      BigNumber.max(maxLovelace.minus(maxFee), maxFee),
      [],
      // Dummy metadata
      '1111111111111111111111111111111111111111'
    )

    return BigNumber.max(maxLovelace.minus(fee), 0).toString()
  } catch {
    return undefined
  }
}

export async function createTransaction(
  walletId: 'nami' | 'eternl',
  fromAddress: string,
  toAddress: string,
  lovelace: BigNumber | undefined,
  assets: Asset[],
  metadata?: string
): Promise<TransactionResult> {
  const protocolParameter = await connector.fetchProtocolParams()

  const utxos = await getUtxos(walletId)
  const unspentOutputs = SL.TransactionUnspentOutputs.new()
  utxos.forEach((utxo) => unspentOutputs.add(utxo))

  const lovelaceSL = lovelace
    ? SL.BigNum.from_str(lovelace.toString())
    : SL.BigNum.zero()

  const multiAsset = makeMultiAsset(assets)
  const outputBuilder = SL.TransactionOutputBuilder.new()
    .with_address(SL.Address.from_bech32(toAddress))
    .next()
  let output = outputBuilder.with_coin_and_asset(lovelaceSL, multiAsset).build()

  const minAda = getMinAda(protocolParameter, output)

  if (lovelaceSL.less_than(minAda)) {
    output = outputBuilder.with_coin_and_asset(minAda, multiAsset).build()
  }

  let txBuilderConfig = SL.TransactionBuilderConfigBuilder.new()
    .fee_algo(
      SL.LinearFee.new(
        SL.BigNum.from_str(protocolParameter.linearFee.minFeeA),
        SL.BigNum.from_str(protocolParameter.linearFee.minFeeB)
      )
    )
    .pool_deposit(SL.BigNum.from_str(protocolParameter.poolDeposit))
    .key_deposit(SL.BigNum.from_str(protocolParameter.keyDeposit))
    .max_value_size(protocolParameter.maxValSize)
    .max_tx_size(protocolParameter.maxTxSize)
  if (isVasil) {
    txBuilderConfig = txBuilderConfig.coins_per_utxo_byte(
      SL.BigNum.from_str(protocolParameter.coinsPerUtxoSize)
    )
  } else {
    txBuilderConfig = txBuilderConfig.coins_per_utxo_word(
      SL.BigNum.from_str(protocolParameter.coinsPerUtxoWord)
    )
  }

  const txBuilder = SL.TransactionBuilder.new(txBuilderConfig.build())

  txBuilder.add_output(output)

  // RandomImproveMultiAsset is not used because of this bug:
  // https://github.com/Emurgo/cardano-serialization-lib/issues/467
  txBuilder.add_inputs_from(
    unspentOutputs,
    SL.CoinSelectionStrategyCIP2.LargestFirstMultiAsset
  )
  txBuilder.add_change_if_needed(SL.Address.from_bech32(fromAddress))

  let auxiliaryData
  if (metadata) {
    const auxiliaryMetadata = SL.GeneralTransactionMetadata.new()
    auxiliaryMetadata.insert(
      SL.BigNum.from_str('0'),
      SL.TransactionMetadatum.new_bytes(Buffer.from(metadata, 'hex'))
    )
    auxiliaryData = SL.AuxiliaryData.new()
    auxiliaryData.set_metadata(auxiliaryMetadata)
    txBuilder.set_auxiliary_data(auxiliaryData)
  }

  const transaction = SL.Transaction.new(
    txBuilder.build(),
    SL.TransactionWitnessSet.new(),
    auxiliaryData
  )

  const fee = new BigNumber(transaction.body().fee().to_str())

  const lovelaceWithMinAda = new BigNumber(output.amount().coin().to_str())

  return { transaction, lovelace: lovelaceWithMinAda, fee }
}

function makeMultiAsset(assets: Asset[]): SL.MultiAsset {
  const assetsByPolicyId = assets.reduce(
    (groupedPolicies: Map<string, Asset[]>, asset: Asset) => {
      const group = asset.policyId
      if (!groupedPolicies.get(group)) groupedPolicies.set(group, [])
      groupedPolicies.get(group)!.push(asset)
      return groupedPolicies
    },
    new Map()
  )

  const multiAsset = SL.MultiAsset.new()
  assetsByPolicyId.forEach((assetList, policy) => {
    const assets = SL.Assets.new()

    assetList.forEach((asset) => {
      const assetName = SL.AssetName.new(Buffer.from(asset.assetName, 'hex'))
      const BigNum = SL.BigNum.from_str(asset.quantity.toString())
      assets.insert(assetName, BigNum)
    })

    const scriptHashPolicy = SL.ScriptHash.from_bytes(
      Buffer.from(String(policy), 'hex')
    )
    multiAsset.insert(scriptHashPolicy, assets)
  })
  return multiAsset
}

export function formatTransactionInputs(
  utxos: SL.TransactionUnspentOutput[]
): [string, { address: string; value: object }][] {
  const formatTxIn = (input: SL.TransactionInput) => {
    const txId = Buffer.from(input.transaction_id().to_bytes()).toString('hex')
    const txIx = input.index()
    return `${txId}#${txIx}`
  }

  const formatValue = (value: SL.Value) => {
    const lovelaceAmount = new BigNumber(value.coin().to_str())
    const result: Record<string, any> = { lovelace: lovelaceAmount }
    const multiasset = value.multiasset()
    if (multiasset) {
      const policyIds = multiasset.keys()
      for (let i = 0; i < policyIds.len(); i++) {
        const policyId = policyIds.get(i)
        const assets = multiasset.get(policyId)
        if (assets) {
          const policyIdAsString = Buffer.from(policyId.to_bytes()).toString(
            'hex'
          )
          result[policyIdAsString] = result[policyIdAsString] ?? {}
          const assetNames = assets.keys()
          for (let j = 0; j < assetNames.len(); j++) {
            const assetName = assetNames.get(j)
            const assetNameAsString = Buffer.from(assetName.name()).toString(
              'hex'
            )
            const quantity = assets.get(assetName)
            if (quantity) {
              result[policyIdAsString][assetNameAsString] = new BigNumber(
                quantity.to_str()
              )
            }
          }
        }
      }
    }
    return result
  }

  const formatTxOut = (output: SL.TransactionOutput) => {
    return {
      address: output.address().to_bech32(),
      value: formatValue(output.amount()),
    }
  }

  return utxos.map((utxo) => [
    formatTxIn(utxo.input()),
    formatTxOut(utxo.output()),
  ])
}
